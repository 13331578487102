<template>
  <div>
    <!--loading-->
    <vs-progress color="primary" :height="1" v-if="isLoadingInitData"/>

    <div class="vx-row mb-3" v-if="initData.spkD.id_spk">
      <div class="vx-col lg:w-9/12 w-full">
        <vx-card>
          <vs-tabs alignment="fixed" v-model="activeTab">
            <vs-tab label="General" icon-pack="feather">
              <TabGeneral :isActive="activeTab === 0" :initData="initData"/>
            </vs-tab>
            <vs-tab label="Detail Teknik" icon-pack="feather">
              <TabTeknik :isActive="activeTab === 1" :idSpk="initData.spkD.id_spk" :idSpkD="initData.spkD.id"/>
            </vs-tab>
            <vs-tab label="Detail Pajak" icon-pack="feather">
              <TabTax :isActive="activeTab === 2" :idSpk="initData.spkD.id_spk" :idSpkD="initData.spkD.id" :spk="initData.spk"/>
            </vs-tab>
            <vs-tab label="Detail Akunting" icon-pack="feather">
              <TabAkunting :isActive="activeTab === 3" :idSpk="initData.spkD.id_spk" :idSpkD="initData.spkD.id" :spkD="initData.spkD"/>
            </vs-tab>
          </vs-tabs>
        </vx-card>
      </div>

      <div class="vx-col lg:w-3/12 w-full mt-3 lg:mt-0">
        <!--approval progress-->
        <vx-card>
          <div class="flex items-center mb-base">
            <feather-icon icon="LayersIcon" svgClasses="h-5 w-5" class="mr-2"/>
            <h5 class="font-medium">Approval Progress</h5>
          </div>
          <ApprovalProgress
            ref="approvalProgress"
            type="SPP-TERMIN"
            :id-ref="$route.params.idSpkD"
            :isShowSigningAction="true"
            @signingClicked="onClickSigningAction"/>
        </vx-card>
      </div>
    </div>

    <!--modals-->
    <SpkTerminApprovalSigning :isActive.sync="modalSigning.active"
                             :idSpkD="modalSigning.idSpkD"
                             :status="modalSigning.status"
                             @success="onSigned"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moduleSpkTerminApproval from '@/store/modules/approvals/spk-termin-approval/spk-termin-approval.store'
import SpkRepository from '@/repositories/proyek/spk-repository'

export default {
  name: 'SpkTerminApprovalShow',
  components: {
    TabGeneral: () => import('@/views/pages/approvals/spk-termin-approval/parts/TabGeneral'),
    TabTeknik: () => import('@/views/pages/approvals/spk-termin-approval/parts/TabTeknik'),
    TabTax: () => import('@/views/pages/approvals/spk-termin-approval/parts/TabTax'),
    TabAkunting: () => import('@/views/pages/approvals/spk-termin-approval/parts/TabAkunting'),
    SpkTerminApprovalSigning: () => import('@/views/pages/approvals/spk-termin-approval/SpkTerminApprovalSigning'),
    ApprovalProgress: () => import('@/views/components/approval-progress/ApprovalProgress')
  },
  mounted () {
    this.getInitData()
  },
  data () {
    return {
      isLoadingInitData: false,
      initData: {
        spkD: {},
        spk: {}
      },
      modalSigning: {
        active: false,
        idSpkD: this.$route.params.idSpkD,
        status: null
      }
    }
  },
  computed: {
    activeTab: {
      get () {
        return this.$store.state.approvals.spkTerminApproval.activeTab
      },
      set (value) {
        this.$store.commit('approvals/spkTerminApproval/SET_ACTIVE_TAB', value)
      }
    },
    ...mapState({
      dataTabGeneral: state => state.approvals.spkTerminApproval.tabGeneral,
      dataTabTeknik: state => state.approvals.spkTerminApproval.tabTeknik,
      dataTabTax: state => state.approvals.spkTerminApproval.tabTax,
      dataTabAkunting: state => state.approvals.spkTerminApproval.tabAkunting
    })
  },
  methods: {
    onSigned () {
      this.getInitData()
      this.$refs.approvalProgress.refresh()
    },

    getInitData () {
      this.isLoadingInitData = true

      const idSpkD = this.$route.params.idSpkD
      SpkRepository.showTermin(idSpkD)
        .then(response => {
          this.initData = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoadingInitData = false
        })
    },

    onClickSigningAction (status) {
      this.modalSigning.status = status
      this.modalSigning.active = true
    }
  },
  beforeCreate () {
    this.$store.registerModule(['approvals', 'spkTerminApproval'], moduleSpkTerminApproval)
  },
  beforeDestroy () {
    this.$store.commit('approvals/spkTerminApproval/RESET_STATE')
    this.$store.unregisterModule(['approvals', 'spkTerminApproval'])
  }
}
</script>
